import gql from 'graphql-tag';

export const CAROUSEL_CONTENT_FRAGMENT = gql`
  fragment CarouselContentTileFields on Tile {
    sys {
      id
    }
    __typename
  }
  fragment CarouselContentEventFields on Event {
    sys {
      id
    }
    __typename
  }
`;
